
const report = {
    namespaced: true,
    state(){
        return {
            reports:[],
            report:[],
            veriables:[],
            headers:[],
            header:null,
            components:[],
            component:null,
            componentVeriables:[]
        }
    },
    mutations:{
        setReports(state,payload){
            state.reports = payload
        },
        setReport(state,payload){
            state.report = payload
        },
        setVeriables(state,payload){
            state.veriables = payload
        },

        //Report Header Elements
        setReportHeaders(state,payload){
            state.headers = payload
        },
        setReportHeader(state,payload){
            state.header = payload
        },
        //Report Component
        setReportComponents(state,payload){
            state.components = payload
        },
        setReportComponent(state,payload){
            state.component = payload
        },
        setComponentVeriable(state,payload){
            state.componentVeriables = payload
        }
    },
    actions:{

        async newReport({commit},payload){
            let req = await axios.post("/report/new-report",payload).then((res)=>{
                toastr[res.data.type](res.data.message);
                return res.data.data;
            }).catch((res)=>toastr[res.data.type](res.data.message));
            return req;
        },

        async getReports({commit}){
            let req = await axios.post("/report/list").then((res)=>{
                let data = res.data.data;
                commit("setReports",data)
            })
            return req;
        },

        async getReport({commit},payload){
            let req = await axios.post("/report/get-report",payload).then((res)=>{
                commit("setReport",res.data)
            })
            return req;
        },

        async getVeriable({commit},payload){
            let req = await axios.post("/report/veriable",payload).then((res)=>{
                commit("setVeriables",res.data)
            })
            return req;
        },

        async saveDesinger({commit},payload){
            let req = await axios.post("/report/save-desinger",payload).then((res)=>{
                toastr[res.data.type](res.data.message);
            }).catch((res)=>toastr[res.data.type](res.data.message));
            return req;
        },

        async getPdf({commit},payload){
            let req = await axios.post("/report/get-pdf",payload).then((res)=>{
                return res;
            }).catch(()=>toastr.error("Something went wrong"));
            return req;
        },

        //Report Header Elements
        async getReportHeaders({commit}){
            let req = await axios.post("/report/headers").then((res)=>{
                let data = res.data;
                commit("setReportHeaders",data)
            })
            return req;
        },

        async saveReportHeader({},payload){
            let req = await axios.post("/report/save-header-data",payload).then((res)=>{
                toastr[res.data.type](res.data.message);
            }).catch(()=>toastr.error("Something went wrong"));
            return req;
        },

        async getHeaderElement({commit},payload){
            let req = axios.post("/report/get-header-element",payload).then((res)=>{
                commit("setReportHeader",res.data)
            })
            return req;
        },

        async createReport({},payload){
            let req = axios.post("/report/create-header",payload).then((res)=>{
                toastr[res.data.type](res.data.message);
                return res.data.data;
            }).catch(()=>toastr.error("Something went wrong"));
            return req;
        },

        //Report Component
        async getReportComponents({commit}){
            let req = await axios.post("/report/components").then((res)=>{
                let data = res.data;
                commit("setReportComponents",data)
            })
            return req;
        },

        async saveComponent({},payload){
            let req = axios.post("/report/create-component",payload).then((res)=>{
                toastr[res.data.type](res.data.message);
                return res.data.data;
            }).catch(()=>toastr.error("Something went wrong"));
            return req;
        },

        async getComponent({commit},payload){
            let req = axios.post("/report/get-component",payload).then((res)=>{
                commit("setReportComponent",res.data)
            })
            return req;
        },

        async saveComponentData({commit},payload){
            let req = await axios.post("/report/save-component",payload).then((res)=>{
                toastr[res.data.type](res.data.message);
            }).catch(()=>toastr.error("Something went wrong"));
            return req;
        },

        async getComponentVeriable({commit},payload){
            let req = axios.post("/report/get-component-veriable",payload).then((res)=>{
                commit("setComponentVeriable",res.data)
            })
            return req;
        }



    }

}

export default report
