<template>
  <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
    <div v-if="authKey != null" class="app-page flex-column flex-column-fluid" id="kt_app_page">
      <Header />
      <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
        <Sidebar />
        <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
          <div class="d-flex flex-column flex-column-fluid">
            <RouterView />
          </div>
          <Footer />
        </div>
      </div>
    </div>
      <div v-else class="d-flex flex-column flex-lg-row flex-column-fluid">
          <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
              <div class="d-flex flex-center flex-column flex-lg-row-fluid">
                  <div class="w-lg-500px p-10">
                      <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" action="#">
                          <div class="text-center mb-11">
                              <h1 class="text-dark fw-bolder mb-3">Welcome Report Designer</h1>
                              <div class="text-gray-500 fw-semibold fs-6">Login</div>
                          </div>
                          <div class="fv-row mb-8">
                              <input type="email" v-model="state.username" placeholder="Enter your e-mail"  autocomplete="off" class="form-control bg-transparent" />
                          </div>
                          <div class="fv-row mb-3">
                              <input type="password" v-model="state.password" placeholder="Enter your password"   autocomplete="off" class="form-control bg-transparent" />
                          </div>
                          <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8 justify-content-center">
                              <button @click="login" type="button" id="kt_sign_in_submit" class="btn btn-primary">
                                  <span class="indicator-label">Login</span>
                              </button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>


<script setup>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";

import { useRouter } from 'vue-router'
import { onMounted } from '@vue/runtime-core'
import {computed, onBeforeMount, reactive} from "vue";

//store import
import { useStore } from 'vuex'
const store = useStore();

//import router
const router = useRouter()
let currentPathObject = router.currentRoute;
const authKey = computed(()=>store.state.auth.authKey)

const  state = reactive({
    username:null,
    password:null
});

const login = ()=>{
    if (state.username == null && state.password == null){
        toastr.error("Please fill in the fields");
        return false;
    }

    store.dispatch("auth/login",{username:state.username,password:state.password}).then((res)=>{
        window.location.reload();
    })
}

const controlAuthState = ()=>{
    store.dispatch("auth/controlAuthState")
}

onBeforeMount( async ()=>{
    controlAuthState();
})

onMounted(() => {

})


</script>

