<template>
    <div id="kt_app_content" class="app-content flex-column-fluid">
        <div id="kt_app_content_container" class="app-container ">
            <div class="card card-flush">
                <div class="card-header border-0 pt-6 d-flex ">
                    <div class="p-2 flex-grow-1 ">
                        <div class="card-title justify-content-between d-flex">
                            <div class="d-flex fs-3 fw-bolder align-items-center position-relative my-1">
                                Component Designers
                            </div>
                            <div class="d-flex fs-3 fw-bolder align-items-center position-relative my-1">
                                <button class="btn btn-primary btn-sm" @click="router.push({name:'componentDesignerNew'})">Add New</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="table-responsive">
                        <table class="table table-bordered table-row-dashed">
                            <thead>
                            <tr class="fw-bold fs-6 text-gray-800">
                                <th scope="col">Name</th>
                                <th scope="col">Created At</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in reportComponents" :key="item.id">
                                <td>{{item.name}}</td>
                                <td>{{turkishFormatDateTime(item.created_at)}}</td>
                                <td>
                                    <button @click="editItem(item.id)" class="btn btn-secondary btn-sm">Edit</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { onMounted } from '@vue/runtime-core'
import {computed, onBeforeMount, reactive} from "vue";

//store import
import { useStore } from 'vuex'
const store = useStore();

//import router
const router = useRouter()
let currentPathObject = router.currentRoute;

//import helper
import Helper from '@/router/helper.js';
const {turkishFormatDateTime} = Helper();


const  state = reactive({

});

const reportComponents = computed(()=>store.state.report.components)


const getReportHeaders = ()=>{
    store.dispatch("report/getReportComponents")
}

const editItem = (id)=>{
    router.push("component-designer/"+id)
}

onBeforeMount( async ()=>{

})

onMounted(() => {
    getReportHeaders();
})

</script>
