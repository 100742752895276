<template>
  <div id="kt_app_content" class="app-content flex-column-fluid">
    <div id="kt_app_content_container" class="app-container ">
      <div class="card card-flush">
        <div class="card-header">
          <div class="card-title fs-3">Report Desinger</div>
          <div class="card-title">
            <button class="btn btn-secondary btn-sm me-2" @click="returnBack()">Back</button>
            <button class="btn btn-success btn-sm" @click="desingerSave()">Save</button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-3 p-5">
                <div class=" mt-2">
                    <span class="fs-3">GENERAL SETTINGS</span>
                    <div class="row">
                        <div class="mb-3">
                            <label class="form-label">Paper Size</label>
                            <select class="form-select form-select-sm" aria-label="Default select example" v-model="state.paperSize">
                                <option selected value="A4">A4</option>
                                <option value="A5">A5</option>
                                <option value="A2">A2</option>
                                <option value="A3">A3</option>
                            </select>
                        </div>
                    </div>
                </div>
                <hr>

                <div class="mt-2" v-if="state.imageEditArea">
                    <span class="fs-3 mb-3">Image Properties</span>
                    <div class="row">
                        <div class="mb-3">
                            <label class="form-label">Image Width</label>
                            <input type="number" class="form-control form-control-sm" v-model="droppedItems[state.imageEditItem].image.width">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Image Height</label>
                            <input type="number" class="form-control form-control-sm" v-model="droppedItems[state.imageEditItem].image.height">
                        </div>
                        <button @click="deleteItemArea(droppedItems[state.imageEditItem])" class="btn btn-light-danger">Delete Element</button>
                    </div>
                </div>

                <div class="mt-2" v-if="state.tableEditArea">
                    <span class="fs-3 mb-3">Table Properties</span>
                    <div class="row">
                        <div class="mb-3">
                            <label class="form-label">Page Break Item</label>
                            <input type="number" class="form-control form-control-sm" v-model="droppedItems[state.tableEditItem].settings.page_break_item">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Table Header Bg Color</label>
                            <input type="text" class="form-control form-control-sm" v-model="droppedItems[state.tableEditItem].settings.header_bg_color">
                        </div>
                        <button @click="deleteItemArea(droppedItems[state.tableEditItem])" class="btn btn-light-danger mt-1">Delete Element</button>
                    </div>
                </div>

                <div class="mt-2" v-if="state.headerEditArea">
                    <span class="fs-3 mb-3">Header Properties</span>
                    <div class="row">
                        <button @click="deleteItemArea(droppedItems[state.headerEditItem])" class="btn btn-light-danger">Delete Element</button>
                    </div>
                </div>

                <div class="mt-2" v-if="state.componentEditArea">
                    <span class="fs-3 mb-3">Properties</span>
                    <div class="row">
                        <button @click="deleteItemArea(droppedItems[state.componentEditItem])" class="btn btn-light-danger">Delete Element</button>
                    </div>
                </div>

                <div class="mt-2" v-if="state.veriableEditArea">
                    <span class="fs-3 mb-3">Veriable Properties</span>
                    <div class="row">
                        <div class="mb-3">
                            <label class="form-label">Is Header Element</label>
                            <input type="number" class="form-control form-control-sm" v-model="droppedItems[state.veriableEditItem].is_header">
                        </div>
                    </div>

                    <div class="row">
                        <div class="mb-3">
                            <label class="form-label">Font Size</label>
                            <input type="text" class="form-control form-control-sm" v-model="droppedItems[state.veriableEditItem].setting['font-size']">
                        </div>
                    </div>

                    <div class="row">
                        <div class="mb-3">
                            <label class="form-label">Font Weight</label>
                            <input type="text" class="form-control form-control-sm" v-model="droppedItems[state.veriableEditItem].setting['font-weight']">
                        </div>
                    </div>
                    <button @click="deleteItemArea(droppedItems[state.veriableEditItem])" class="btn btn-light-danger">Delete Element</button>
                </div>

              <div class="mb-2 d-flex justify-content-between">
                  <span class="fs-3 mb-2">VERIABLES</span>
              </div>
                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3"
                     v-for="(item, index) in veriables" :key="index" draggable="true" @dragstart="dragStart(item, $event)" @dragend="dragEnd(item, $event)"
                >
                    {{ item.name }}
                </div>
              <hr>

            </div>
            <div class="col-9">
              <div class="drop-area grid-background border border-4 mb-2" @dragover="dragOver" @drop="drop" :style="{ width: state.paperWidth +'mm', height: state.paperHeight + 'mm'}">
                <div :class="findClass(item)" v-for="(item, index) in droppedItems" :key="index" draggable="true" @dragstart="dragStart(item, $event)" @dragend="dragEnd(item, $event)" :style="item.type === 'design-header' ? { position: 'absolute', left: '4px', top: '2px' } : { position: 'absolute', left: item.x + 'px', top: item.y + 'px' }">
                  <span class=" border border-1" @click="editVeriableArea(item)" v-if="item.type == 'veriable'">
                    {{ item.name }}
                  </span>

                  <div class="border border-2" @click="editHeaderArea(item)" style="width: 205mm !important;" v-if="item.type== 'design-header'">
                      <div class="header-table" v-html="item.data"></div>
                  </div>

                  <div class="border border-2" @click="editComponentArea(item)" style="width: 205mm !important;" v-if="item.type== 'component'">
                      <div class="component" v-html="item.data"></div>
                  </div>

                  <div class="border border-2" v-if="item.type== 'table'" style="z-index: 2">
                    <div class="" @click="editItemTable(item)">
                      <table class="">
                        <thead>
                          <tr class="fw-bold fs-6 border border-1">
                            <th class="border border-1" :colspan="row.colspan" v-for="row in item.table.header">{{ row.data }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="border border-1">
                            <td class="border border-1" v-for="row in item.table.data[0][0]">Test Data</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="" v-if="item.type== 'image'" style="z-index: 2">
                      <img @click="editItemArea(item)" :style="{width:item.image.width+'px', height: item.image.height+'px'}" :src="item.image.url">
                  </div>

                  <div class="w-100" v-if="item.type== 'page_break'" style="z-index: 2">
                      <strong class="cursor-pointer" @click="deleteItemArea(item)">X</strong>
                      <hr style="width: 150mm; border: 3px solid;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { onMounted } from '@vue/runtime-core'
import {computed, onUnmounted, reactive, ref, watch} from "vue";

//store import
import { useStore } from 'vuex'
const store = useStore();

//import router
const router = useRouter()
let currentPathObject = router.currentRoute;

const { id } = router.currentRoute.value.params;

const state = reactive({
    paperSize:"A4",
    paperWidth:"210",
    paperHeight:"297",
    imageEditArea:false,
    imageEditItem:null,
    tableEditArea:false,
    tableEditItem:null,
    veriableEditArea:false,
    veriableEditItem:null,
    headerEditArea:false,
    headerEditItem:null,
    componentEditArea:false,
    componentEditItem:null,
});

const report = computed(()=>store.state.report.report);
const veriables = computed(()=>store.state.report.veriables);
const droppedItems = ref([]);

const dragStart = (item, event) => {
  let element = event.target;
  $(element).addClass("mouse-effect")
  event.dataTransfer.effectAllowed = 'move';
  event.dataTransfer.setData('item-id', item.id);
};

const dragEnd = (item, event) => {
  let element = event.target;
  $(element).removeClass("mouse-effect")
  const dropAreaRect = event.currentTarget.parentNode.getBoundingClientRect();
  const itemRect = event.currentTarget.getBoundingClientRect();
  const x = event.clientX - dropAreaRect.left - parseInt(itemRect.width) / 2;
  const y = event.clientY - dropAreaRect.top - parseInt(itemRect.height) / 2;
  item.x = x;
  item.y = y;
};

const dragOver = event => {
  event.preventDefault();
};

const drop = event => {
  event.preventDefault();
  const itemId = event.dataTransfer.getData('item-id');

  let item;
  let check=true;
  item = droppedItems.value.find(i => i.id == itemId);
  if (!item){
    check = false
    item = veriables.value.find(i => i.id == itemId);
  }


  if (item) {
    const dropAreaRect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - dropAreaRect.left;
    const y = event.clientY - dropAreaRect.top;

    if (!check) {
        if (item.type == "page_break"){
            //birden fazla eklenebilir ise
            let newItem = JSON.parse(JSON.stringify(item));
            newItem.id = Math.floor(Math.random() * 100000) + 1;
            droppedItems.value.push({ ...newItem, x, y });
        }else{
            droppedItems.value.push({ ...item, x, y });
        }
    } else {
      item.x = x;
      item.y = y;
    }
  }
};

const deleteItemArea = (item)=>{
 let deletedItem = droppedItems.value.findIndex(i => i.id == item.id);
  droppedItems.value.splice(deletedItem,1);
    state.imageEditArea=false
    state.imageEditItem=null
    state.tableEditArea=false
    state.tableEditItem=null
    state.veriableEditArea=false
    state.veriableEditItem=null
    state.headerEditArea=false
    state.headerEditItem=null
    state.componentEditArea=false
    state.componentEditItem=null
}

const editItemArea = (item)=>{
    let dropItem = droppedItems.value.findIndex(i => i.id == item.id);
    if (dropItem !== -1 && droppedItems.value[dropItem].type === "image"){
        state.imageEditArea = !state.imageEditArea;
        state.imageEditItem=dropItem;
    }
}

const editHeaderArea = (item)=>{
    let dropItem = droppedItems.value.findIndex(i => i.id == item.id);
    if (dropItem !== -1 && droppedItems.value[dropItem].type === "design-header"){
        state.headerEditArea = !state.headerEditArea;
        state.headerEditItem=dropItem;
    }
}

const editComponentArea = (item)=>{
    let dropItem = droppedItems.value.findIndex(i => i.id == item.id);
    if (dropItem !== -1 && droppedItems.value[dropItem].type === "component"){
        state.componentEditArea = !state.componentEditArea;
        state.componentEditItem=dropItem;
    }
}


const editItemTable = (item)=>{
    let dropItem = droppedItems.value.findIndex(i => i.id == item.id);
    if (dropItem !== -1 && droppedItems.value[dropItem].type === "table"){
        state.tableEditArea = !state.tableEditArea;
        state.tableEditItem=dropItem;
    }
}

const editVeriableArea = (item)=>{
    let dropItem = droppedItems.value.findIndex(i => i.id == item.id);
    if (dropItem !== -1 && droppedItems.value[dropItem].type === "veriable"){
        state.veriableEditArea = !state.veriableEditArea;
        state.veriableEditItem=dropItem;
    }
}

const desingerSave = ()=>{
  store.dispatch("report/saveDesinger",{id:id, dropItems:droppedItems.value, paper_size:state.paperSize})
}

const getReport = ()=>{
  store.dispatch("report/getReport",{id:id}).then(()=>{
    if (report.value.data.coordinates != null && report.value.data.coordinates != "" && report.value.data.coordinates != undefined){
      let reportData = JSON.parse(report.value.data.coordinates);
      reportData.forEach((el,i)=>{
        droppedItems.value.push(el);
      })
      state.paperSize = report.value.data.paper_size
    }
  })
}

const findClass = (item)=>{
    if (item.type !=="design-header"){
        return "drop-area-no-header"
    }else{
        return "drop-area-div"
    }
}

const getVeriable = ()=>{
  store.dispatch("report/getVeriable",{id:id}).then((res)=>{

  })
}

const returnBack = ()=>{
    router.push({name:"home"})
}


watch(()=>state.paperSize, (val) => {
  if (val == "A4"){
      state.paperWidth ="210";
      state.paperHeight="297";
  }
  if (val == "A5"){
    state.paperWidth ="148";
    state.paperHeight="210";
  }
  if (val == "A2"){
    state.paperWidth ="420";
    state.paperHeight="594";
  }
  if (val == "A3"){
    state.paperWidth ="297";
    state.paperHeight="420";
  }
})


onMounted(() => {
  getReport();
  getVeriable();
})

</script>


<style>
.drop-area {
  border: 1px solid black;
  position: relative;
}

.grid-background {
    background-image:
            radial-gradient(circle, rgba(0,0,0,0.1) 10%, transparent 10%),
            radial-gradient(circle, rgba(0,0,0,0.1) 10%, transparent 10%);

    background-size: 10px 10px;
    background-position: 0 0, 5px 5px;
}
.t-border{
    border: 1px solid !important;
}

.header-table tr td {
    border: 1px solid;
}

.header-table{
    z-index: -1;
}

.drop-area-no-header{
    z-index: 100;
}

.mouse-effect{
    box-shadow: 0 0 1px 2px rgba(20, 30, 150, 0.8);
}

</style>

