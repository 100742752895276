<template>
    <div id="kt_app_content" class="app-content flex-column-fluid">
        <div id="kt_app_content_container" class="app-container ">
            <div class="card card-flush">
                <div class="card-header border-0 pt-6 d-flex ">
                    <div class="p-2 flex-grow-1 ">
                        <div class="card-title">
                            <div class="d-flex fs-3 fw-bolder align-items-center position-relative my-1">
                                New Header Designer
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="row">
                        <form>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Header Name</label>
                                <input type="text" class="form-control form-control-sm" v-model="report_name">
                            </div>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Select Report</label>
                                <select class="form-select form-select-sm" v-model="report_id">
                                    <option v-for="opt in reports" :value="opt.id">{{opt.name}}</option>
                                </select>
                            </div>
                            <button type="button" class="btn btn-primary" @click="saveHeader()">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { onMounted } from '@vue/runtime-core'
import {computed, onBeforeMount, reactive, ref} from "vue";

//store import
import { useStore } from 'vuex'
const store = useStore();

//import router
const router = useRouter()
let currentPathObject = router.currentRoute;

const report_id = ref(null);
const report_name = ref(null);
const reports = computed(()=>store.state.report.reports)


const getReports = ()=>{
    store.dispatch("report/getReports")
}

const saveHeader = ()=>{
    store.dispatch("report/createReport",{report_id: report_id.value, report_name:report_name.value}).then((res)=>{
        router.push("/header-designer/"+res.id)
    })
}



onBeforeMount( async ()=>{
    getReports();
})


</script>
