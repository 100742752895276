import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from "@/store";
import axios from "@/config/axios";
window.axios = axios;
import {DraggableResizableVue} from "draggable-resizable-vue3";


const app = createApp(App)
app.use(router)
app.use(store)
app.use(DraggableResizableVue)
app.mount('#kt_app_body')
