<template>
    <div id="kt_app_content" class="app-content flex-column-fluid">
        <div id="kt_app_content_container" class="app-container ">
            <div class="card card-flush">
                <div class="card-header border-0 pt-6 d-flex ">
                    <div class="p-2 flex-grow-1 ">
                        <div class="card-title fs-4 fw-bold d-flex justify-content-between">
                            <span>Header Designer</span>
                            <div>
                                <button class="btn btn-secondary btn-sm me-2" @click="returnBack()">Back</button>
                                <button class="btn btn-success btn-sm" @click="saveHeaderData()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="alert alert-info d-flex flex-column">
                            <span>Table Width: 774px</span>
                            <span>Table Border: 1px</span>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label">Select Report</label>
                        <select class="form-select form-select-sm" v-model="report_id">
                            <option v-for="opt in reports" :value="opt.id">{{opt.name}}</option>
                        </select>
                    </div>

                    <div class="row">
                        <editor
                            api-key="no-api-key"
                            :init="{
                                entity_encoding : 'raw',
                              menubar: false,
                              plugins: 'lists link image emoticons table code',
                              toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | code',
                              table_background_color_map: [
                                { title: 'Red', value: 'FF0000' },
                                { title: 'White', value: 'FFFFFF' },
                                { title: 'Yellow', value: 'F1C40F' }
                              ]
                            }"
                            v-model="editorData"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, reactive, ref} from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {component  as CKEditor} from '@ckeditor/ckeditor5-vue'
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {onMounted} from "@vue/runtime-core";
import Editor from '@tinymce/tinymce-vue'

const router = useRouter();
let currentRouteObject = router.currentRoute

const store = useStore();

const state = reactive({
    editor:ClassicEditor
})


const {id} = currentRouteObject.value.params;
const editorData = ref();
const editorConfig = ref( {
});
const report_id = ref(null);

const header = computed(()=>store.state.report.header);
const reports = computed(()=>store.state.report.reports)

const getReports = ()=>{
    store.dispatch("report/getReports")
}

const getHeaderElement = ()=>{
    store.dispatch("report/getHeaderElement",{id:id}).then(()=>{
        let val = header.value;
        editorData.value = val.data;
        report_id.value = val.report_id;
    })
}

const saveHeaderData = ()=>{
    store.dispatch("report/saveReportHeader",{id:id,data:editorData.value,report_id:report_id.value});
}

const returnBack = ()=>{
    router.push({name:"headerDesigner"})
}

onMounted(()=>{
    getHeaderElement();
    getReports();
})

</script>
