import store from "@/store/index";
import {computed} from "vue";

export default function () {
    const formatPrice = (price) => new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2
    }).format(price)

    const checkIdentity = (TCNO)=>{
        var tek = 0,
            cift = 0,
            sonuc = 0,
            TCToplam = 0,
            i = 0,
            hatali = [11111111110, 22222222220, 33333333330, 44444444440, 55555555550, 66666666660, 7777777770, 88888888880, 99999999990];;

        if (TCNO.length != 11) return false;
        if (isNaN(TCNO)) return false;
        if (TCNO[0] == 0) return false;

        tek = parseInt(TCNO[0]) + parseInt(TCNO[2]) + parseInt(TCNO[4]) + parseInt(TCNO[6]) + parseInt(TCNO[8]);
        cift = parseInt(TCNO[1]) + parseInt(TCNO[3]) + parseInt(TCNO[5]) + parseInt(TCNO[7]);

        tek = tek * 7;
        sonuc = Math.abs(tek - cift);
        if (sonuc % 10 != TCNO[9]) return false;

        for (var i = 0; i < 10; i++) {
            TCToplam += parseInt(TCNO[i]);
        }

        if (TCToplam % 10 != TCNO[10]) return false;

        if (hatali.toString().indexOf(TCNO) != -1) return false;

        return true;
    }

    const checkTaxNumber = (value) => {
        if(value.length >10 || value.length <10) {
            return false;
        }
        return true;

        /*let first = value.substring(0,1);
        let second = value.substring(1,2);
        let third = value.substring(2,3);
        let fourth = value.substring(3,4);
        let fifth = value.substring(4,5);
        let sixth = value.substring(5,6);
        let seventh = value.substring(6,7);
        let eighth = value.substring(7,8);
        let ninth = value.substring(8,9);
        let tenth = value.substring(9,10);

        let firstNumber = parseInt(first);
        let secondNumber = parseInt(second);
        let thirdNumber = parseInt(third);
        let fourthNumber = parseInt(fourth);
        let fifthNumber = parseInt(fifth);
        let sixthNumber = parseInt(sixth);
        let seventhNumber = parseInt(seventh);
        let eighthNumber = parseInt(eighth);
        let ninthNumber = parseInt(ninth);
        let tenthNumber = parseInt(tenth);

        let full_array = [firstNumber, secondNumber, thirdNumber, fourthNumber, fifthNumber, sixthNumber, seventhNumber, eighthNumber, ninthNumber];

        let firstStep = [];
        let secondStep = [];
        let thirdStep = [];
        let fourthStep = [];
        let fifthStep = [];
        let sixthStep = [];
        let seventhStep = [];

        //step 1 9'dan başlayarak 1'er azaltarak toplama
        let step1Start = 9;
        for (let index = 0; index < full_array.length; index++) {
            firstStep[index] = full_array[index] + step1Start;
            step1Start--;
        }

        //step 2 10'a bölümünden kalan
        for (let index = 0; index < firstStep.length; index++) {
            let mod = firstStep[index] %= 10;
            secondStep[index] = mod;
        }


        //step 3 2 üzeri index
        let customIndex = 0;
        for (let index = 9; index > 0; index--) {
            let us = Math.pow(2, index);
            let element = secondStep[customIndex] * us;
            thirdStep[customIndex] = element;
            customIndex++;
        }

        //step 4 9'a bölümünden kalan
        for (let index = 0; index < thirdStep.length; index++) {
            let mod2 = thirdStep[index] %= 9;
            fourthStep[index] = mod2;
        }

        //step son listeyi 5 toplama
        let total = 0;
        for (let index = 0; index < fourthStep.length; index++) {
            const elem = fourthStep[index];
            total += elem;
        }

        //step son 10'a bölümünden kalan
        let totalMod = total %= 10;

        //son step son değeri 10 dan çıkarma
        let lastStep = 10 - totalMod;

        if(parseInt(lastStep) == parseInt(tenthNumber)) {
            return true;
        }else{
            return false;
        }*/
    }

    const turkishFormatDate =(de)=> {
        let monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(de);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        if (day < 10){
            day = '0'+ day
        }
        return `${day}-${monthNames[monthIndex]}-${year}`;
    }

    const turkishFormatDateTime = (de) => {
        let monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(de);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();

        if (day < 10){
            day = '0' + day;
        }

        if (hours < 10){
            hours = '0' + hours;
        }

        if (minutes < 10){
            minutes = '0' + minutes;
        }

        if (seconds < 10){
            seconds = '0' + seconds;
        }

        return `${day}-${monthNames[monthIndex]}-${year} ${hours}:${minutes}:${seconds}`;
    }

    const formatPriceCustom = (price) => {
        let currency_symbol = "₺"
        let formattedOutput = new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY',
            minimumFractionDigits: 2,
        });
        return formattedOutput.format(price).replace(currency_symbol, '')
    }

    const getCurrentCurrency = () => {
        let user = JSON.parse(localStorage.getItem('user'));
        let currency = user.currency;
        let defaultCurrency = null;
        switch (currency) {
            case "TL":
                defaultCurrency = '₺'
                break;
            case "USD":
                defaultCurrency = '$'
                break;
            case "EUR":
                defaultCurrency = '€'
                break;
            default:
                defaultCurrency = '₺'
        }
        return defaultCurrency;
    }

    const formatPhoneNumber = (data)=>{
        let digits = data.replace(/[^0-9]/g, "");
        let formattedNumber = "";

        if (digits.length > 0) {
            formattedNumber += "(" + digits.substring(0, 3) + ")";
        }
        if (digits.length >= 4) {
            formattedNumber += "-" + digits.substring(3, 6) + "-";
        }
        if (digits.length >= 7) {
            formattedNumber += digits.substring(6, 8) + "-";
        }
        if (digits.length >= 9) {
            formattedNumber += digits.substring(8, 10);
        }
        data = formattedNumber;
        return data;
    }

    const noFormatPhoneNumber  = (number) =>{
        const convertedPhoneNumber = number.replace(/[^0-9]/g, "");
        return convertedPhoneNumber;
    }

    const findPermission = (permission_name)=>{
        let permissions = JSON.parse(localStorage.getItem('user')).permissions;
        let find = permissions.find((el)=>el.name === permission_name)
        if (find !== undefined)
            return true
        else
            return false
    }

    const showConfirmation = (text=null) => {
        return Swal.fire({
            title: 'Emin misiniz!',
            text: (text !== null) ? text : 'Bu işleme devam etmek istiyor musunuz?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Evet, Devam Et!',
            cancelButtonText: 'Hayır, iptal et!',
            reverseButtons: true
        });
    };

    const showSuccess = (text) => {
        return Swal.fire({
            position: 'middle',
            icon: 'success',
            title: text,
            showConfirmButton: false,
            timer: 2000
        })
    };

    const showInfo = (text)=>{
        return Swal.fire(
            'Bildirim!',
            text,
            'success'
        )
    }

    const showError = (text) => {
        return Swal.fire(
            'Hata.!',
            text,
            'error'
        )
    };

    const showToastError = (text) => {
        toastr.error(text)
        return false;
    };

    const showToastSuccess = (text) => {
        toastr.success(text);
        return false;
    };

    const showToastInfo = (text) => {
        toastr.info(text)
        return false;
    };

    const redirectPdfFile = (item_code)=>{
        if (item_code === null){
            return toastr.error("Lütfen ürün seçiniz")
        }
        let code = item_code.split('.')
        code = code[0]+'.'+code[1]+'.'+code[2];
        let link = 'https://www.galamobilya.com/portal/pdf/'+code;
        window.open(link, '_blank');
    }

    const hideModal = (ID)=>{
        $("#"+ID).modal("hide");
    }

    const previousPage = (router)=>{
        router.go(-1);
    }


    return {
        checkIdentity,
        checkTaxNumber,
        turkishFormatDate,
        turkishFormatDateTime,
        formatPrice,
        formatPriceCustom,
        getCurrentCurrency,
        formatPhoneNumber,
        noFormatPhoneNumber,
        findPermission,
        showConfirmation,
        showSuccess,
        showError,
        redirectPdfFile,
        showToastError,
        showToastSuccess,
        showToastInfo,
        hideModal,
        showInfo,
        previousPage,
    }
}
