<template>
  <div id="kt_app_content" class="app-content flex-column-fluid">
    <div id="kt_app_content_container" class="app-container ">
        <div class="card card-flush mb-2">
            <div class="card-header border-0 pt-2 d-flex ">
                <div class="card-title">
                    <div class="d-flex fs-3 fw-bolder align-items-center position-relative my-1">
                        Reports
                    </div>
                </div>
                <div class="card-title">
                    <div class="d-flex fs-3 fw-bolder align-items-center position-relative my-1">
                        <button @click="()=>$router.push({'name':'newReport'})" class="btn btn-primary btn-sm">New Report</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row g-6 g-xl-9">
          <div class="col-md-6 col-xl-4" v-for="item in reports" :key="item.id">
              <a href="javascript:void(0)" class="card border-hover-primary ">
                  <div class="card-body p-9">
                      <div class="fs-3 fw-bold text-gray-900">
                          {{item.name}}
                      </div>

                      <p class="text-gray-500 fw-semibold fs-5 mt-1 mb-7">
                          {{turkishFormatDateTime(item.created_at)}}
                      </p>
                      <div class="d-flex flex-wrap mb-5">
                          <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                              <div class="fs-6 text-gray-800 fw-bold" @click="viewReport(item.id)">View</div>
                          </div>

                          <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                              <div class="fs-6 text-gray-800 fw-bold" @click="editReport(item.id)">Edit</div>
                          </div>
                      </div>
                  </div>
              </a>
          </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { onMounted } from '@vue/runtime-core'
import {computed, onBeforeMount, reactive} from "vue";

//store import
import { useStore } from 'vuex'
const store = useStore();

//import router
const router = useRouter()
let currentPathObject = router.currentRoute;

//import helper
import Helper from '@/router/helper.js';
const {turkishFormatDateTime} = Helper();

const  state = reactive({

});

const reports = computed(()=>store.state.report.reports)


const getReports = ()=>{
  store.dispatch("report/getReports")
}

const editReport = (id)=>{
  router.push("report/"+id)
}

const viewReport = (id)=>{
    store.dispatch("report/getPdf",{id:id}).then((res)=>{
        window.open(res.data.url,"_blank");
    })
}


onBeforeMount( async ()=>{

})

onMounted(() => {
  getReports();
})

</script>
