import { API_URL } from './config.js'
import axios from 'axios'

let instance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
})

// Add interceptors for NProgress


instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export default instance
