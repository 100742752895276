const auth = {
    namespaced: true,
    state(){
        return {
            authKey:null,
            user : [],
        }
    },
    mutations:{
        setAuthKey(state,payload){
            state.authKey = payload
        },
        setAuthUser(state,payload){
            state.user = payload
        },

    },
    actions:{
        async controlAuthState({commit},payload){
            let authKey = localStorage.getItem("authKey")
            if (authKey){
                commit("setAuthKey",authKey)
            }
            return true;
        },

        async login ({commit},payload){
            let req = await axios.post("/auth/login",payload).then((res)=>{
                let data = res.data;
                if (data.status == 1){
                    localStorage.setItem("authKey",data.token)
                    commit("setAuthKey",data.token)
                    commit("setAuthUser",data.user)
                    toastr.success(data.message);
                }else{
                    toastr.error(data.message);
                }

            })
            return req;
        },

        async logout({commit},payload){
            localStorage.removeItem("authKey");
            commit("setAuthKey",null)
            commit("setAuthUser",[])
            toastr.success("Operation is successfully");
            return true;
        }
    }

}

export default auth
