import {createStore} from "vuex";
import report from "@/store/modules/report/report";
import auth from "@/store/modules/report/auth";

export default createStore({
    modules:{
        report,
        auth
    }
})
