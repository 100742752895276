<template>
  <div id="kt_app_content" class="app-content flex-column-fluid">
    <div id="kt_app_content_container" class="app-container ">
      <div class="card card-flush">
        <div class="card-header">
          <div class="card-title">Rapor Viewer</div>
          <div class="card-title">

          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { onMounted } from '@vue/runtime-core'
import {computed, reactive, ref, watch} from "vue";

//store import
import { useStore } from 'vuex'
const store = useStore();

//import router
const router = useRouter()
let currentPathObject = router.currentRoute;

const { id } = router.currentRoute.value.params;

const  state = reactive({

});

const report = computed(()=>store.state.report.report);
const veriables = computed(()=>store.state.report.veriables);
const droppedItems = ref([]);


const getReport = ()=>{
  store.dispatch("report/getReport",{id:id}).then(()=>{
    if (report.value.data.coordinates != null && report.value.data.coordinates != "" && report.value.data.coordinates != undefined){
      let reportData = JSON.parse(report.value.data.coordinates);
      reportData.forEach((el,i)=>{
        droppedItems.value.push(el);
      })
      state.paperSize = report.value.data.paper_size
    }
  })
}

const getVeriable = ()=>{
  store.dispatch("report/getVeriable").then((res)=>{

  })
}




onMounted(() => {
  getReport();
  getVeriable();
})

</script>


<style>
.drop-area {
  border: 1px solid black;
  position: relative;
}
</style>

