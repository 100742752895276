import { createRouter, createWebHistory } from 'vue-router'

import Index from '../report/Index.vue'
import NewReport from '../report/New.vue'
import EditReport from '../report/Edit.vue'

import ViewReport from '../report/View.vue'
import HeaderDesigner from '../report/header/Index.vue'
import HeaderDesignerEdit from '../report/header/Edit.vue'
import HeaderAddNew from  '../report/header/New.vue';

import ComponentDesigner from '../report/component/Index.vue'
import ComponentDesignerEdit from '../report/component/Edit.vue'
import ComponentAddNew from  '../report/component/New.vue';


const routes = [
    {
        path: '/',
        name: 'home',
        component: Index
    },
    {
        path: '/report',
        name: 'newReport',
        component: NewReport
    },
    {
        path: '/report/:id',
        name: 'editReport',
        component: EditReport
    },
    {
        path: '/report-view/:id',
        name: 'viewReport',
        component: ViewReport
    },
    {
        path: '/header-designer',
        name: 'headerDesigner',
        component: HeaderDesigner
    },
    {
        path: '/header-designer/:id',
        name: 'headerDesignerEdit',
        component: HeaderDesignerEdit
    },
    {
        path: '/header-designer-new/',
        name: 'headerDesignerNew',
        component: HeaderAddNew

    },
    {
        path: '/component-designer',
        name: 'componentDesigner',
        component: ComponentDesigner
    },
    {
        path: '/component-designer/:id',
        name: 'componentDesignerEdit',
        component: ComponentDesignerEdit
    },
    {
        path: '/component-designer-new/',
        name: 'componentDesignerNew',
        component: ComponentAddNew
    },
]

const router = createRouter({
    history:createWebHistory(),
    routes,
    linkActiveClass:"active"
})

export default router;
